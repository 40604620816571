import React from 'react'
import PracticesBtn from './singleButtons/PracticesBtn'
import ProfileBtn from './singleButtons/ProfileBtn'
import LogoutBtn from './singleButtons/LogoutBtn'
import { isMobile } from 'react-device-detect'
import HomeBtn from './singleButtons/HomeBtn'
import UsersMenu from './singleButtons/UsersMenu'
import HelpBtn from './singleButtons/HelpBtn'

const ControllerBtns = ({ type }) => {
  return (
    <>
    <HomeBtn type={type} />
    <PracticesBtn type={type} />
    <UsersMenu />
    <ProfileBtn />
    <HelpBtn />
    {isMobile && <LogoutBtn />}
  </>
  )
}

export default ControllerBtns