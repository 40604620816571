import { useEffect } from "react";

import { Link as LinkRouter } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";

import Copyright from "../../components/layout/Copyright";
import LogoPublic from "../../components/layout/LogoPublic";

export default function Home() {
  useEffect(() => {
    document.body.style.backgroundColor = "#005ca9";
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LogoPublic />
        <Divider sx={{ my: 5 }} />
        <Container component={Paper} sx={{ p: 5, mt: -5 }}>
          <Typography component="h1" variant="h5">
            CNA Gestione Sinistro
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
            <Grid>
              <Button
                fullWidth
                component={LinkRouter}
                to="/login"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Accedi
              </Button>
            </Grid>
            <Grid>
              <Button
                fullWidth
                component={LinkRouter}
                to="/register"
                variant="contained"
                sx={{ mb: 2 }}
              >
                Registrati
              </Button>
            </Grid>
            <Grid>
            <Link href="/aiuto" variant="body2">
                  Hai bisogno di aiuto?
                </Link>
            </Grid>
          </Box>
        </Container>
        <Copyright sx={{ color: "white", mt: 2 }} />
      </Box>
    </Container>
  );
}
