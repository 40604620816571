import React from 'react'
import DatabaseBtn from './singleButtons/DatabaseBtn'
import ProfileBtn from './singleButtons/ProfileBtn'
import { isMobile } from 'react-device-detect'
import LogoutBtn from './singleButtons/LogoutBtn'
import HelpBtn from './singleButtons/HelpBtn'

const AdminBtns = ({type}) => {
  return (
    <>
      <DatabaseBtn type={type} />
      <ProfileBtn />
      <HelpBtn />
      {isMobile && <LogoutBtn />}
    </>
  )
}

export default AdminBtns