import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import RequireAuth from "./components/auth/RequireAuth";

import "./App.css";

import * as PublicPages from "./pages/public/Pages";
import * as CommonPages from "./pages/private/common/Pages";
import * as AdminPages from "./pages/private/admin/Pages";
import * as ControllerPages from "./pages/private/controller/Pages";
import * as IntermediaryPages from "./pages/private/intermediary/Pages";
import * as RepairerPages from "./pages/private/repairer/Pages";
import * as CnaPages from "./pages/private/cna/Pages"
import * as tablesPages from "./tables/tablesPages";
// import * as tablesPages from "./tables/tablesPages";

const ROLES = {
	Admin: "9495",
	Cna: "5672",
	Controller: "8711",
	Generic: "7843",
	Intermediary: "2039",
	Repairer: "6093"
  };

function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
{/*\\\\ public routes \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
				<Route path="" element={<PublicPages.Home />} />
				<Route path="*" element={<PublicPages.Missing />} />
				<Route path="unauthorized" element={<PublicPages.Unauthorized />} />
				<Route path="aiuto" element={<PublicPages.Help />} />
				
				<Route path="register" element={<Layout />}>
					<Route path="" element={<PublicPages.Register />} />
					<Route path="convalidate_email_address/:otp" element={<PublicPages.EmailVerification />} />
				</Route>
				
				<Route path="login" element={<Layout />}>
					<Route path="" element={<PublicPages.Login />} />
					<Route path="reset_password" element={<PublicPages.PswReset />} />
					<Route path="reset_password/new/auth" element={<PublicPages.MakeNewPsw />} />
				</Route>

{/*\\\\ private routes \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
				{/* common pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
				<Route element={<RequireAuth allowedRoles={ROLES.Generic} />}>

					<Route path="profilo" element={<Layout />}>
						<Route>
							<Route path="" element={<CommonPages.Profile action={{value: "no-edit"}}/>} />
						</Route>
						<Route>
							<Route path="modifica" element={<CommonPages.Profile action={{value: "edit"}} />} />
						</Route>
						<Route>
							<Route path="modifica_password" element={<CommonPages.Profile action={{value: "edit-psw"}} />} />
						</Route>
					</Route>
				
				</Route>

				{/* admin pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
				<Route element={<RequireAuth allowedRoles={ROLES.Admin} />}>

					<Route path="admin_home" element={<AdminPages.AdminHome roles={ROLES.Admin}/>} />

					<Route path="tables" element={<tablesPages.Tables roles={ROLES.Admin}/>} />

					<Route path="tables/user" element={<Layout />}>
						<Route path="user_verified" element={<tablesPages.User_Verified roles={ROLES.Admin} />} />
						<Route path="user_anagraphics" element={<tablesPages.User_Anagraphics roles={ROLES.Admin} />} />
						<Route path="users" element={<tablesPages.Users roles={ROLES.Admin} showInactive="false" />} />
						<Route path="users/inactive" element={<tablesPages.Users roles={ROLES.Admin} showInactive="true" />} />
						<Route path="roles" element={<tablesPages.Roles roles={ROLES.Admin} />} />
					</Route>

					<Route path="tables/practice" element={<Layout />}>
						<Route path="practice_users" element={<tablesPages.Practice_Users roles={ROLES.Admin} showInactive="false" />} />
						<Route path="practice_users/inactive" element={<tablesPages.Practice_Users roles={ROLES.Admin} showInactive="true" />} />
						<Route path="practice_types" element={<tablesPages.Practice_Types roles={ROLES.Admin} />} />
						<Route path="practice_states" element={<tablesPages.Practice_States roles={ROLES.Admin} />} />
						<Route path="practice_progresses" element={<tablesPages.Practice_Progresses roles={ROLES.Admin} />} />
						<Route path="practice_messages" element={<tablesPages.Practice_Messages roles={ROLES.Admin} />} />
						<Route path="practice_attachments" element={<tablesPages.Practice_Attachments roles={ROLES.Admin} />} />
						<Route path="practice_additional_infos" element={<tablesPages.Practice_Additional_Infos roles={ROLES.Admin} />} />
						<Route path="practices" element={<tablesPages.Practices roles={ROLES.Admin} showInactive="false" />} />
						<Route path="practices/inactive" element={<tablesPages.Practices roles={ROLES.Admin} showInactive="true" />} />
						<Route path="attachment_types" element={<tablesPages.Attachment_Types roles={ROLES.Admin} />} />
					</Route>

					<Route path="tables/comunication" element={<Layout />}>
						<Route path="notifications" element={<tablesPages.Notifications roles={ROLES.Admin} />} />
						<Route path="message_types" element={<tablesPages.Message_Types roles={ROLES.Admin} />} />
						<Route path="messages" element={<tablesPages.Messages roles={ROLES.Admin} />} />
					</Route>
				
				</Route>

				{/* controller pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
				<Route element={<RequireAuth allowedRoles={ROLES.Controller} />}>

					<Route path="area_controller" element={<Layout />}>

						<Route path="home" element={<ControllerPages.ControllerHome />} />

						<Route path="pratiche" element={<Layout />}>

							<Route path="" element={<ControllerPages.ControllerPractices />} />
							<Route path=":codePractice" element={<ControllerPages.ControllerPracticeDetail />} />
						
						</Route>

						<Route path="utenti" element={<Layout />}>

							<Route path="nuovo" element={<ControllerPages.ControllerUsersNew />} />

							<Route path="riparatori" element={<Layout />}>
						
								<Route path="" element={<ControllerPages.ControllerUsers type="Repairer" />} />

								<Route path=":codeUser" element={<ControllerPages.ControllerUsersDetail type="Repairer" />} />
							
							</Route>

							<Route path="periti" element={<Layout />}>
						
								<Route path="" element={<ControllerPages.ControllerUsers type="Intermediary" />} />

								<Route path=":codeUser" element={<ControllerPages.ControllerUsersDetail type="Intermediary" />} />
							
							</Route>
						
						</Route>

					</Route>

				</Route>

				{/* intermediary pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
				<Route element={<RequireAuth allowedRoles={ROLES.Intermediary} />}>
					<Route path="area_perito" element={<Layout />}>

						<Route path="le_mie_pratiche" element={<Layout />}>

								<Route path="" element={<IntermediaryPages.IntermediaryPractices />} />
								<Route path=":codePractice" element={<IntermediaryPages.IntermediaryPracticeDetail />} />
							
						</Route>
					
					</Route>

				</Route>

				{/* repairer pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
				<Route element={<RequireAuth allowedRoles={ROLES.Repairer} />}>
				
					<Route path="le_mie_pratiche" element={<Layout />}>

						<Route path="" element={<RepairerPages.RepairerPractices />} />
						<Route path=":codePractice" element={<RepairerPages.RepairerPracticeDetail />} />
					
					</Route>

					<Route path="nuova_pratica" element={<RepairerPages.RepairerNewPractice />} />

				</Route>

				{/* CNA pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
				<Route element={<RequireAuth allowedRoles={ROLES.Cna} />}>

					<Route path="statistiche_pratiche" element={<CnaPages.CnaHome />} />

					<Route path="utenti" element={<Layout />}>

						<Route path="riparatori" element={<Layout />}>
					
							<Route path="" element={<CnaPages.CnaUsers type="Repairer" />} />

							<Route path=":codeUser" element={<CnaPages.CnaUsersDetail type="Repairer" />} />
						
						</Route>

						<Route path="periti" element={<Layout />}>
					
							<Route path="" element={<CnaPages.CnaUsers type="Intermediary" />} />

							<Route path=":codeUser" element={<CnaPages.CnaUsersDetail type="Intermediary" />} />
						
						</Route>
					
					</Route>
				
				</Route>

			</Route>

		</Routes>
	);
}

export default App;