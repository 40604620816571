import React from "react";
import NewPracticeBtn from "./singleButtons/NewPracticeBtn";
import ProfileBtn from "./singleButtons/ProfileBtn";
import LogoutBtn from "./singleButtons/LogoutBtn";
import { isMobile } from "react-device-detect";
import MyPracticesBtn from "./singleButtons/MyPracticesBtn";
import HelpBtn from "./singleButtons/HelpBtn";

const IntermediaryBtns = ({ type }) => {
  return (
    <>
      <MyPracticesBtn type={type} />
      <ProfileBtn />
      <HelpBtn />
      {isMobile && <LogoutBtn />}
    </>
  );
};

export default IntermediaryBtns;
