import { Container, CssBaseline, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import LogoPublic from '../../components/layout/LogoPublic'
import Copyright from '../../components/layout/Copyright'
import { isMobile } from 'react-device-detect'

const Help = () => {

    useEffect(() => {
        document.body.style.backgroundColor = "#005ca9";
    }, []);

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    pt: 5,
                    pb: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box width={"100%"}>
                    <LogoPublic />
                </Box>
                <Paper
                    elevation={6}
                    sx={{
                        mt: 1,
                        p: isMobile ? 2 : 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Typography variant="h5">
                        Se hai bisogno di informazioni o aiuto, contattaci!
                    </Typography>
                    <Box sx={{ my: 2, py: 4, border: "1px inset #ddd", borderRadius: 2, width: "100%", background: "#eee" }}>
                        Supporto telefonico: <b>351 667 7974</b>
                        <br />
                        <br />
                        Supporto via mail: <a href="mailto:info@metakol.com?subject=Richiesta%20di%20supporto%20su%20gestionesinistrocna.it"><b>info@metakol.com</b></a>
                        <br />
                        <br />
                        Orari:
                        <br />
                        Lunedì-Venerdì
                        09:00-13:00 - 14:00-17:30
                    </Box>
                    <Copyright />
                </Paper>
            </Box>
        </Container>
    )
}

export default Help