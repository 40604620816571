import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Container,
  Typography,
  CssBaseline,
  InputAdornment,
  Grid2,
  Link,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { axiosAuth } from "../../api/axios";
import Copyright from "../../components/layout/Copyright";
import LogoPublic from "../../components/layout/LogoPublic";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import useGetUserCompleteInfo from "../../hooks/users/useGetUserCompleteInfo";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function SignUp() {
  const navigate = useNavigate();
  const userRole = useGetUserCompleteInfo("");
  const [loading, setLoading] = useState(false);
  const [pswInput, setPswInput] = useState("");
  const [rePswInput, setRePswInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [surnameInput, setSurnameInput] = useState("");
  const [businessNamelInput, setBusinessNameInput] = useState("");
  const [pIvaInput, setPIvaInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [telephoneInput, setTelephoneInput] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#005ca9";
  }, []);

  if (userRole.user && userRole.user["roles.value"]) {
    navigate(userRole.user["roles.value"] === "Admin" ? "/admin" : "/profilo", {
      replace: true,
    });
  }

  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const alphabeticWithSpecialCharsRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ' -]{1,50}$/;
  const alphanumericalWithAllSpecialCharsRegex =
    /^[0-9a-zA-ZÀ-ÖØ-öø-ÿ!@#$%^&*(),.?":{}|<>_\-\\/[\]';\s]{1,50}$/;
  const pIvaRegex = /^\d{11}$/;
  const phoneNumberRegex = /^[+\d\s()-]{7,15}$/;

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    axiosAuth
      .post("/register", {
        email: emailInput,
        password: pswInput,
        name: nameInput,
        surname: surnameInput,
        business_name: businessNamelInput,
        telephone: telephoneInput,
        p_iva: pIvaInput,
        address: addressInput,
        privacy: checked
      })
      .then(() => {
        alert("Registrazione avvenuta con successo!");
        window.location.href = "/login";
      })
      .catch((err) => {
        setLoading(false);
        console.log(err)
        alert(err.response.data.detail);
        if (err.response?.status === 401)
          window.location.href = "https://associati.cna.it/";
      });
  };

  const isFormValid = () => {
    return (
      emailRegex.test(emailInput) &&
      pswInput.length >= 8 &&
      pswInput === rePswInput &&
      checked
    );
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          mt: isMobile ? 4 : 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LogoPublic />
        <Paper
          elevation={6}
          sx={{
            mt: 1,
            p: isMobile ? 2 : 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography component="h1" variant="h5">
            Registrazione
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ my: 3 }}
          >
            <TextField
              size="small"
              required
              fullWidth
              label="Nome"
              value={nameInput}
              error={
                !alphabeticWithSpecialCharsRegex.test(nameInput) &&
                nameInput.length > 0
              }
              helperText={
                !alphabeticWithSpecialCharsRegex.test(nameInput) &&
                nameInput.length > 0 &&
                "Nome non valido"
              }
              onChange={(e) => setNameInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <TextField
              size="small"
              required
              fullWidth
              label="Cognome"
              value={surnameInput}
              error={
                !alphabeticWithSpecialCharsRegex.test(surnameInput) &&
                surnameInput.length > 0
              }
              helperText={
                !alphabeticWithSpecialCharsRegex.test(surnameInput) &&
                surnameInput.length > 0 &&
                "Cognome non valido"
              }
              onChange={(e) => setSurnameInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <TextField
              size="small"
              required
              fullWidth
              label="Ragione Sociale"
              value={businessNamelInput}
              error={
                !alphanumericalWithAllSpecialCharsRegex.test(
                  businessNamelInput
                ) && businessNamelInput.length > 0
              }
              helperText={
                !alphanumericalWithAllSpecialCharsRegex.test(
                  businessNamelInput
                ) &&
                businessNamelInput.length > 0 &&
                "Ragione Sociale non valida"
              }
              onChange={(e) => setBusinessNameInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <TextField
              size="small"
              required
              fullWidth
              label="Partita IVA"
              value={pIvaInput}
              error={!pIvaRegex.test(pIvaInput) && pIvaInput.length > 0}
              helperText={
                !pIvaRegex.test(pIvaInput) &&
                pIvaInput.length > 0 &&
                "Formato partita iva non valido"
              }
              onChange={(e) => setPIvaInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <TextField
              size="small"
              required
              fullWidth
              label="Indirizzo"
              value={addressInput}
              error={
                !alphanumericalWithAllSpecialCharsRegex.test(addressInput) &&
                addressInput.length > 0
              }
              helperText={
                !alphanumericalWithAllSpecialCharsRegex.test(addressInput) &&
                addressInput.length > 0 &&
                "Indirizzo non valido"
              }
              onChange={(e) => setAddressInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <TextField
              size="small"
              required
              fullWidth
              label="Email"
              value={emailInput}
              error={!emailRegex.test(emailInput) && emailInput.length > 0}
              helperText={
                !emailRegex.test(emailInput) &&
                emailInput.length > 0 &&
                "Email non valida"
              }
              onChange={(e) => setEmailInput(e.target.value)}
              sx={{ mb: 1, mt: 2 }}
            />
            <TextField
              size="small"
              required
              fullWidth
              label="Telefono"
              value={telephoneInput}
              error={
                !phoneNumberRegex.test(telephoneInput) &&
                telephoneInput.length > 0
              }
              helperText={
                !phoneNumberRegex.test(telephoneInput) &&
                telephoneInput.length > 0 &&
                "Numero di telefono non valido"
              }
              onChange={(e) => setTelephoneInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <TextField
              size="small"
              required
              fullWidth
              type={isPasswordVisible ? "text" : "password"}
              label="Password"
              value={pswInput}
              error={pswInput.length < 8 && pswInput.length > 0}
              helperText={
                pswInput.length < 8 &&
                pswInput.length > 0 &&
                "Minimo 8 caratteri"
              }
              onChange={(e) => setPswInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <Button
                      tabindex="-1"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 1, mt: 2 }}
            />
            <TextField
              size="small"
              required
              fullWidth
              type={isPasswordVisible ? "text" : "password"}
              label="Ripeti Password"
              value={rePswInput}
              error={pswInput !== rePswInput}
              helperText={
                pswInput !== rePswInput && "Le password devono coincidere"
              }
              onChange={(e) => setRePswInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <Box sx={{ mx: "auto", width: "90%" }}>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} sx={{ mt: -3 }} />}
                label={
                  <Typography variant="subtitle2" textAlign="left" fontWeight="bold">
                    Dichiaro di aver letto e di accettare{" "}
                    <a
                      href={`${process.env.PUBLIC_URL}/documents/Informativa privacy GestioneSinistri CNA - DEFINITIVO.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      l'informativa privacy (clicca qui per visualizzarla)
                    </a>
                    {" "}*
                  </Typography>
                }
                sx={{ mt: 2 }}
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isFormValid() || loading}
            >
              {loading ? <LoadingSpinner /> : "Registrati"}
            </Button>
            <Grid2 container justifyContent="space-between">
              <Grid2>
                <Link href="/aiuto" variant="body2">
                  Hai bisogno di aiuto?
                </Link>
              </Grid2>
              <Grid2 item>
                <Link href="/login" variant="body2">
                  Hai già un account? Accedi qui!
                </Link>
              </Grid2>
            </Grid2>
          </Box>
          <Copyright />
        </Paper>
      </Box>
    </Container>
  );
}
