import React from "react";
import CnaStatsBtn from "./singleButtons/CnaStatsBtn";
import ProfileBtn from "./singleButtons/ProfileBtn";
import LogoutBtn from "./singleButtons/LogoutBtn";
import { isMobile } from "react-device-detect";
import HelpBtn from "./singleButtons/HelpBtn";

const CnaBtns = ({ type }) => {
  return (
    <>
      <CnaStatsBtn type={type} />
      <ProfileBtn />
      <HelpBtn />
      {isMobile && <LogoutBtn />}
    </>
  );
};

export default CnaBtns;
